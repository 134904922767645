<template>

  <view-content :back="{name:'Settings'}">

    <template v-slot:header>
      Send Messages
    </template>

    <template v-slot:subtext>
      <p><strong class="text-gray-100">Messaging Costs (10c)</strong></p>
      <p>A third party service is used to send these messages. The cost to use this service is 10c (AUD) per 160
        characters, and will be added as a charge to your congregation account at the end of the month.</p>
    </template>

    <div class="text-left">

      <tuxedo-select
        name="to"
        :label="'Send To'"
        v-model="compose.to"
        :options="options"
        :insistReturn="'id'"
        inputClass="bg-white" />

      <tuxedo-select
        v-if="to === 7"
        name="to"
        :label="'Send To'"
        v-model="compose.group"
        :options="store.all('groups')"
        :insistReturn="'id'"
        :insistLabel="'name'"
        inputClass="bg-white" />

      <input-message :label="'Message (Characters: '+characterCount+')'"
                 v-model="compose.message"
                 :help="'Use the shortcode [name] to personalise the message for each recipient.'" />

    </div>
    <button style="float:right;margin-top:15px" class="btn btn-success" @click="!submitting ? sendMessages() : null" :disabled="!validated">
      <fa-icon :icon="['fal', 'spinner-third']" spin v-if="submitting" />
      <span v-if="!submitting">Send</span>
    </button>

  </view-content>

</template>

<script setup>
import { ref, computed, watch } from 'vue'
import axios from 'axios'
import { useCollectionsStore } from '@/stores/collections'

const store = useCollectionsStore()

const compose = ref({
  to: null,
  group: null,
  message: null
})

const options = [
  { id: 1, label: 'Elders' },
  { id: 2, label: 'Servants' },
  { id: 3, label: 'Appointed Men' },
  { id: 4, label: 'Pioneers' },
  { id: 5, label: 'Cart Approved Publishers' },
  { id: 6, label: 'Publishers' },
  { id: 7, label: 'Specific Group' }
]

const submitting = ref(false)

const to = computed(() => {
  return parseInt(compose.value.to)
})

const validated = computed(() => {
  if (compose.value.to && compose.value.message) {
    return !(to.value === 7 && !compose.value.group)
  }
  return false
})

const characterCount = computed(() => {
  if (compose.value.message) {
    return compose.value.message.length
  }
  return 0
})

watch(to, (val) => {
  if (val !== 7) compose.value.group = null
})

const sendMessages = () => {
  submitting.value = true
  axios.post('api/send-messages', compose.value)
    .then(response => {
      submitting.value = false
      compose.value = {
        to: null,
        group: null,
        message: null
      }
    })
    .catch(e => {
      this.$emit('update:error', e)
    })
}

</script>
