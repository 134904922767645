import { defineStore } from 'pinia'
import { useAPIStore } from './api'

import { orderBy, filter, remove } from 'lodash'
import { singular, ucFirst } from '@/helpers'

export const useCollectionsStore = defineStore({
  id: 'collections',
  persist: true,
  state: () => ({
    cache: {},
    countries: [],
    countriesMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'name',
        dir: 'asc'
      }
    },
    groups: [],
    groupsMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'name',
        dir: 'asc'
      }
    },
    meetings: [],
    meetingsMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'start_date',
        dir: 'asc'
      }
    },
    meetingAssignments: [],
    meetingAssignmentsMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'meeting_id',
        dir: 'asc'
      }
    },
    meetingAttendance: [],
    meetingAttendanceMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'meeting_id',
        dir: 'asc'
      }
    },
    meetingParts: [],
    meetingPartsMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'meeting_id',
        dir: 'asc'
      }
    },
    permissions: [],
    permissionsMeta: {
      show: 1000,
      page: 1,
      total: 1000,
      start: 1,
      end: 1000,
      sort: {
        by: 'name',
        dir: 'asc'
      }
    },
    regions: [],
    regionsMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'name',
        dir: 'asc'
      }
    },
    roles: [],
    rolesMeta: {
      show: 1000,
      page: 1,
      total: 1000,
      start: 1,
      end: 1000,
      sort: {
        by: 'name',
        dir: 'asc'
      }
    },
    sharedCarts: [],
    sharedCartsMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'location',
        dir: 'asc'
      }
    },
    users: [],
    usersMeta: {
      show: 50,
      page: 1,
      total: 0,
      start: 1,
      end: 50,
      sort: {
        by: 'last_first',
        dir: 'asc'
      }
    }
  }),
  getters: {
  },
  actions: {
    all (collection, id = null, children = null) {
      let resources = children ? this[children] : this[collection]
      if (children) {
        const foreignKey = singular(collection) + '_id'
        resources = filter(resources, [foreignKey, parseInt(id)])
      }
      return orderBy(resources,
        [this[(children ?? collection) + 'Meta'].sort.by],
        [this[(children ?? collection) + 'Meta'].sort.dir])
    },
    get (payload, options = {}) {
      return new Promise((resolve, reject) => {
        const [collection, meta, uri] = this.configureCollection(payload)
        if (!options.force && this.collectionUpToDate(collection)) return resolve()
        const api = useAPIStore()
        let config = {}
        if ('live' in this[meta] && this[meta].live) {
          config = {
            params: {
              page: this[meta].page,
              sortBy: this[meta].sort.by,
              sortDir: this[meta].sort.dir,
              filters: this[meta].filters
            }
          }
        }
        api.get(uri, config)
          .then(res => {
            this.handleServerResponse(collection, res)

            resolve()
          })
          .catch(error => reject(error))
      })
    },
    merge (collection, data) {
      // Determine the actual data layer for the response
      while ('data' in data) data = data.data

      // Force data to be an array for processing
      if (!Array.isArray(data)) data = [data]

      data.forEach(o => {
        remove(this[collection], i => i.id === o.id)
        this[collection].push(o)
      })

      this.setDBState(collection, this[collection])
    },
    forget (collection, object, targetUsing) {
      targetUsing = targetUsing ?? 'id'
      const target = typeof object === 'object' ? object[targetUsing] : object
      remove(this[collection], i => i[targetUsing] === target)
      this.setDBState(collection, this[collection])
      // This is just to update for reactivity
      this[collection].reverse()
    },
    find (collection, id, key = 'id') {
      return this[collection].find(i => i[key] === parseInt(id))
    },
    collectionUpToDate (collection) {
      if (!(collection in this)) {
        console.error('collection \'' + collection + '\' does not exist')
        return false
      }
      if (this[collection].length === 0) {
        console.info('collection \'' + collection + '\' is empty')
        return false
      }
      const latest = this[collection].map(i => i.updated_at ?? '2014-01-01 00:00:00').sort().reverse()[0]
      const timestamp = Math.floor(new Date(latest).getTime() / 1000)
      const cachestamp = String(this[collection].length).padStart(10, '0') + '-' + timestamp
      console.log('checking \'' + collection + '\' cache')
      console.info('local: ' + cachestamp + ', remote: ' + this.cache[collection])
      return cachestamp === this.cache[collection]
    },
    configureCollection (collection) {
      if (Array.isArray(collection)) {
        if (collection.length === 1) return [collection[0], collection[0] + 'Meta', collection]
        if (collection.length === 3) {
          const ref = singular(collection[0]) + ucFirst(collection[2])
          return [ref, ref + 'Meta', collection]
        }
      }

      return [collection, collection + 'Meta', [collection]]
    },
    handleServerResponse (collection, response) {
      let data = response

      // Determine the actual data layer for the response
      while (typeof data === 'object' && 'data' in data) {
        if ('meta' in data) this.handleServerMeta(collection, data.meta)
        data = data.data
      }

      this.setState(collection, data)
    },
    handleServerMeta (collection, meta) {
      console.log('server side pagination detected for \'' + collection + '\'')
      this[collection + 'Meta'].live = true
      this[collection + 'Meta'].show = meta.per_page
      this[collection + 'Meta'].page = meta.current_page
      this[collection + 'Meta'].total = meta.total
      this[collection + 'Meta'].start = meta.from
      this[collection + 'Meta'].end = meta.to
    }
  }
})
