<template>
  <div>
    <div class="w-100 mt-2">
      <div v-for="(a, key) in resources" :key="'ma2' + key">
        <div class="flex align-items-center justify-content-between py-1 border-t">
          <div>
            <div class="font-medium">
              <div v-if="a.returned_at" class="flex">
                <div class="w-28">{{ date(a.assigned_at) }}</div>
                <div class="w-7">&mdash;</div>
                <div class="w-28">{{ date(a.returned_at) }}</div>
                <div><small>{{ diff(a.assigned_at, a.returned_at) }} days</small></div>
              </div>
              <div v-else class="flex">
                <div class="w-28">{{ date(a.assigned_at) }}</div>
                <div class="w-7">&mdash;</div>
                <div class="w-28">Current</div>
                <div><small>{{ diff(a.assigned_at, null) }} days</small></div>
              </div>
            </div>
            <div>
              <small>
                {{ $t('resources.count', {count: a.users.length, resource: $tc('resources.assignment', a.users.length) }) }}
              </small>
            </div>
          </div>
          <div>
            <action :default="true" :small="true" @click="expand(a.id)">
              <span v-if="expandedAssignments === a.id">{{ $t('actions.collapse') }}</span>
              <span v-else>{{ $t('actions.expand') }}</span>
            </action>
          </div>
        </div>
        <div v-for="(user, akey) in a.users" :key="'mas' + akey">
          <div v-if="expandedAssignments === a.id" class="flex align-items-center justify-content-between py-1 border-t">
            <div>
              <div>{{ find(users, 'id', user.id, 'last_first') }}</div>
              <div>
                <span v-if="user.pivot.returned_at">
                  {{ date(user.pivot.assigned_at) }} &mdash; {{ date(user.pivot.returned_at) }} <small>({{ diff(user.pivot.assigned_at, user.pivot.returned_at) }} days)</small>
                </span>
                <span v-else>
                  {{ date(user.pivot.assigned_at) }} &mdash; Current <small>({{ diff(user.pivot.assigned_at, null) }} days)</small>
                </span>
              </div>
            </div>
            <div v-if="assignmentDeleteArray.includes(user.id)" class="flex">
              <undo-timer :ms="3500" @click="cancel(user)" />
            </div>
            <div v-else>
              <button @click="deleteAssignment(a, user)" class="btn btn-danger btn-round" :disabled="assignmentDeleteArray.includes(user.id)">
                <fa-icon :icon="['fal', 'trash']" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { destroy } from '@/composables/serverInterface'
import { merge } from '@/composables/dataInterface'
import { Sec } from '@/mixins/sec'

defineProps(['map', 'resources'])

const store = useCollectionsStore()

const users = computed(() => store.all('users'))

const initialResource = {
  id: null
}
const resource = ref(null)
const expandedAssignments = ref(null)
const assignmentDeleteArray = ref([])

onMounted(() => {
  resource.value = { ...initialResource }
})

const expand = (id) => {
  if (expandedAssignments.value === id) expandedAssignments.value = null
  else expandedAssignments.value = id
}
const date = (d) => {
  return new Sec(d).format('Y, M jS')
}
const diff = (from, to) => {
  to = to || new Date()
  return new Sec(to).diff(new Sec(from), 'days')
}

const deleteAssignment = (a, u) => {
  assignmentDeleteArray.value.push(u.id)
  setTimeout(() => {
    if (assignmentDeleteArray.value.includes(u.id)) {
      destroy(['maps', a.map_id, 'assignments', a.id, 'users', u.id],
        { data: { assigned_at: u.pivot.assigned_at } })
        .then(res => {
          const key = assignmentDeleteArray.value.indexOf(u.id)
          assignmentDeleteArray.value.splice(key, 1)
          merge('mapAssignments', res)
        })
    }
  }, 3000)
}

const cancel = (u) => {
  const key = assignmentDeleteArray.value.indexOf(u.id)
  assignmentDeleteArray.value.splice(key, 1)
}
</script>
